<template>
  <div>
    <!-- 公共头部 -->
    <Header ></Header>

    <template v-if="tikaData.problem.length">
      <div class="section3">
        <div class="left-box">
          <!-- 题目显示区 -->
          <div class="view1">
            <span class="type">{{ tikaData.problem[curIndex].type }}</span>
            <div class="question_title">
              {{ curIndex + 1 }}/{{ tikaData.problem.length }}、{{
                tikaData.problem[curIndex].title
              }}
            </div>
            <img
              class="img"
              v-show="tikaData.problem[curIndex].img"
              :src="tikaData.problem[curIndex].img"
            />
          </div>
          <!-- 选项显示区 文字类型 -->
          <div class="view2" v-if="tikaData.problem[curIndex].p_type == 1">
            <div
              class="item"
              v-for="(item, index) in tikaData.problem[curIndex].answer"
              :key="index"
            >
              {{ item.option }}、{{ item.title }}
            </div>
          </div>
          <!-- 选项显示区 图片类型 -->
          <div
            class="view2_pic clearfix"
            v-else-if="tikaData.problem[curIndex].p_type == 2"
          >
            <div
              class="item fl"
              v-for="(item, index) in tikaData.problem[curIndex].answer"
              :key="index"
            >
              <div class="pic-box">
                <img class="pic" :src="item.img" />
              </div>
              <div class="title">{{ item.option }}</div>
            </div>
          </div>
          <!-- 作答显示区 -->
          <div class="view3">
            <div
              class="item"
              :class="[item.user_choose ? 'active' : '']"
              v-for="(item, index) in tikaData.problem[curIndex].answer"
              @click="chooseAnswer(index)"
              :key="index"
            >
              {{ item.option }}
            </div>
          </div>
        </div>

        <div class="button_group">
          <div class="btn btn1" @click="prev">上一题</div>
          <div class="btn" v-if="showDTK" @click="showDTK = false">
            收起答题卡
          </div>
          <div class="btn" v-else-if="!showDTK" @click="showDTK = true">
            显示答题卡
          </div>
          <div class="btn btn1" @click="next">下一题</div>
        </div>
      </div>

      <div class="mask" v-if="showDTK" @click="showDTK = false"></div>
      <div class="blank_100"></div>
      <div class="tools-box">
        <div class="tools">
          <!-- 交卷按钮 -->
          <span @click="showMask = true" class="submit">交卷</span>
          <!-- 交卷按钮 -->

          <!-- 数字盒子 -->
          <div class="num-box">
            <div class="item">
              {{ curIndex + 1 }}/{{ tikaData.problem.length }}
            </div>
          </div>
          <!-- 数字盒子 -->
        </div>
        <div class="view3" v-show="showDTK">
          <ul class="list clearfix">
            <li
              class="item fl"
              v-for="(item, index) in tikaData.problem"
              @click="curIndex = index"
              :class="[
                curIndex == index ? 'green' : '',
                item.status == 1 ? 'orange' : '',
              ]"
              :key="index"
            >
              {{ index + 1 }}
            </li>
          </ul>
          <div class="close-btn">
            <span class="btn" @click="showDTK = false">关闭</span>
          </div>
        </div>
      </div>
    </template>

    <div v-else class="no-list">
      <img class="icon" src="@/assets/images/icon103.png" />
      <div class="text">暂时还没有任何题目哦~</div>
      <div
        class="btn"
        @click="
          $router.replace({
            path: '/simulation/choose',
            query: { student_id: $route.query.student_id },
          })
        "
      >
        返回上一层
      </div>
    </div>

    <div class="mask" v-if="showMask"></div>
    <div class="alert-box" :class="[showMask ? 'active' : '']">
      <h2 class="cate-title">交卷确认</h2>
      <div class="desc">
        <p>1、点击【确认交卷】，将提交考试成绩，结束考试。</p>
        <p>2、点击【继续答题】，将继续答题,倒计时结束无法继续答题。</p>
      </div>
      <div class="button_group">
        <div class="btn" @click="showMask = false">取消</div>
        <div class="btn btn1" @click="submit">确认交卷</div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Public/Header.vue";
export default {
  components: {
    Header,
  },
  data() {
    return {
      tikaData: {
        exam_num: 0,
        exam_time: 0,
        problem: [],
        subject_title: "",
      },
      showTime: "00:00:00",
      timer: "", //定时器名字
      curIndex: 0,
      showMask: false,
      showDTK: false,
    };
  },
  activated() {
    this.getTika();
    window.onbeforeunload = function (e) {
      return "";
    };
  },
  deactivated() {
    window.onbeforeunload = null;
    clearInterval(this.timer);
    this.tikaData = {
      exam_num: 0,
      exam_time: 0,
      problem: [
        {
          type: "",
        },
      ],
      subject_title: "",
    };
    this.showTime = "00:00:00";
    this.timer = "";
    this.curIndex = 0;
    this.showMask = false;
  },
  methods: {
    // 交卷
    async submit() {
      var problem = this.tikaData.problem;
      var answerArray = [];
      let timer = new Date();
      console.log(timer.getDate() + "=======");
      let y = timer.getFullYear();
      let m =
        timer.getMonth() + 1 >= 10
          ? timer.getMonth() + 1
          : "0" + timer.getMonth() + 1;
      let d = timer.getDate() >= 10 ? timer.getDate() : "0" + timer.getDate();
      let h =
        timer.getHours() >= 10 ? timer.getHours() : "0" + timer.getHours();
      let mi =
        timer.getMinutes() >= 10
          ? timer.getMinutes()
          : "0" + timer.getMinutes();
      let s =
        timer.getSeconds() >= 10
          ? timer.getSeconds()
          : "0" + timer.getSeconds();
      let time = `${y}年${m}月${d}日${h}:${mi}:${s}`;
      console.log(time);
      for (var i = 0; i < problem.length; i++) {
        var temp = problem[i].id + "_" + problem[i].user_answer;
        answerArray.push(temp);
      }
      var answer = answerArray.join("|");
      var exam_id = this.$route.query.id;
      var student_id = this.$route.query.student_id;
      var times = this.showTime;
      var data = {
        answer,
        exam_id,
        student_id,
        times,
      };
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.post("/exam/jiaojuan", data);
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "正式考试 交卷：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        this.$router.replace(
          "/Examination/status?id=" + res.data + "&time=" + time
        );
      } else {
        this.$messageBox.alert(res.data, "温馨提示");
      }
    },
    // 题目作答
    chooseAnswer(index) {
      var tikaData = this.tikaData;
      var curIndex = this.curIndex;
      if (
        tikaData.problem[curIndex].type == "单选题" ||
        tikaData.problem[curIndex].type == "判断题"
      ) {
        for (var i = 0; i < tikaData.problem[curIndex]["answer"].length; i++) {
          if (index == i) {
            tikaData.problem[curIndex]["answer"][i].user_choose =
              !tikaData.problem[curIndex]["answer"][i].user_choose;
            if (tikaData.problem[curIndex]["answer"][i].user_choose) {
              tikaData.problem[curIndex].status = 1;
              tikaData.problem[curIndex].user_answer =
                tikaData.problem[curIndex]["answer"][i].option;
            } else {
              tikaData.problem[curIndex].status = 0;
              tikaData.problem[curIndex].user_answer = "";
            }
          } else {
            tikaData.problem[curIndex]["answer"][i].user_choose = false;
          }
        }
      }
      if (tikaData.problem[curIndex].type == "多选题") {
        var chooseList = [];
        for (var i = 0; i < tikaData.problem[curIndex]["answer"].length; i++) {
          if (index == i) {
            tikaData.problem[curIndex]["answer"][i].user_choose =
              !tikaData.problem[curIndex]["answer"][i].user_choose;
          }
          if (tikaData.problem[curIndex]["answer"][i].user_choose) {
            chooseList.push(tikaData.problem[curIndex]["answer"][i].option);
          }
        }
        if (chooseList.length) {
          tikaData.problem[curIndex].status = 1;
          tikaData.problem[curIndex].user_answer = chooseList.join("");
        } else {
          tikaData.problem[curIndex].status = 0;
          tikaData.problem[curIndex].user_answer = "";
        }
      }
    },
    //小于10的格式化函数
    timeFormat(param) {
      return param < 10 ? "0" + param : param;
    },
    // 倒计时函数
    countDown() {
      var currentTime = this.tikaData.exam_time * 60;
      var that = this;
      that.timer = setInterval(function () {
        currentTime--;
        var hou = parseInt((currentTime % (60 * 60 * 24)) / 3600);
        var min = parseInt(((currentTime % (60 * 60 * 24)) % 3600) / 60);
        var sec = parseInt(((currentTime % (60 * 60 * 24)) % 3600) % 60);
        var showTime =
          that.timeFormat(hou) +
          ":" +
          that.timeFormat(min) +
          ":" +
          that.timeFormat(sec);
        that.showTime = showTime;
        if (currentTime == 0) {
          clearInterval(that.timer);
          that.submit();
        }
      }, 1000);
    },
    // 获取题目列表
    async getTika() {
      var student_id = this.$route.query.student_id;
      var id = this.$route.query.id;
      var data = {
        student_id,
        id,
      };
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.get("/exam/formal_exam", {
        params: data,
      });
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "正式考试 题目数据：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        var tikaData = res.data;
        for (var i = 0; i < tikaData.problem.length; i++) {
          tikaData.problem[i].user_answer = ""; // 用户答题记录
          tikaData.problem[i]["status"] = 0; // status 0初始化 1已作答
          if (
            tikaData.problem[i]["img"] &&
            tikaData.problem[i]["img"].substring(0, 4) != "http"
          ) {
            tikaData.problem[i]["img"] =
              this.$store.state.http + tikaData.problem[i]["img"];
          }
          for (var j = 0; j < tikaData.problem[i]["answer"].length; j++) {
            tikaData.problem[i]["answer"][j]["user_choose"] = false;
            if (tikaData.problem[i]["p_type"] == 2) {
              if (
                tikaData.problem[i]["answer"][j]["img"] &&
                tikaData.problem[i]["answer"][j]["img"].substring(0, 4) !=
                  "http"
              ) {
                tikaData.problem[i]["answer"][j]["img"] =
                  this.$store.state.http +
                  tikaData.problem[i]["answer"][j]["img"];
              }
            }
          }
        }
        this.tikaData = tikaData;
        this.countDown();
      } else {
        this.$messageBox.alert(res.data, "温馨提示");
      }
    },
    // 上一题
    prev() {
      if (this.curIndex != 0) {
        this.curIndex = --this.curIndex;
      } else {
        this.$toast.info("已经到达第一题");
      }
    },
    // 下一题
    next() {
      if (this.curIndex + 1 != this.tikaData.problem.length) {
        this.curIndex = ++this.curIndex;
      } else {
        this.$toast.info("已经到达最后一题");
      }
    },
  },
};
</script>

<style scoped>
.section3 {
  background: #ffffff;
  padding: 0.3rem;
  margin-bottom: 0.3rem;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
}
.section3 .left-box {
  position: relative;
}
.section3 .left-box .view1 {
  position: relative;
  padding-left: 1.1rem;
}
.section3 .left-box .view1 .type {
  width: 0.9rem;
  height: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #121e5f;
  font-size: 0.24rem;
  color: #ffffff;
  position: absolute;
  border-radius: 0.06rem;
  left: 0;
  top: 0;
}
.section3 .left-box .view1 .question_title {
  font-size: 0.26rem;
  color: #333333;
  line-height: 0.4rem;
}
.section3 .left-box .view1 .img {
  display: block;
  margin: 0.3rem auto 0;
}
.section3 .left-box .view2 {
  margin-top: 0.2rem;
}
.section3 .left-box .view2 .item {
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.4rem;
  padding: 0.12rem 0;
}

.section3 .left-box .view2_pic {
  margin-top: 10px;
  margin-left: -10px;
  margin-right: -10px;
}
.section3 .left-box .view2_pic .item {
  width: 25%;
  padding: 10px;
}
.section3 .left-box .view2_pic .item .pic-box {
  height: 0;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  border: 1px solid #a8a8a8;
}
.section3 .left-box .view2_pic .item .pic-box .pic {
  position: absolute;
  max-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.section3 .left-box .view2_pic .item .title {
  text-align: center;
  font-size: 0.26rem;
  color: #333333;
  padding: 10px 0;
}

.section3 .left-box .view3 {
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
}
.section3 .left-box .view3 .item {
  width: 1.5rem;
  height: 0.8rem;
  border: 0.02rem solid #b0b0b0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
  font-size: 0.3rem;
  color: #000000;
  margin-right: 0.3rem;
}
.section3 .left-box .view3 .item:nth-child(4n) {
  margin-right: 0;
}
.section3 .left-box .view3 .item.active {
  background: #121e5f;
  color: #ffffff;
  border: 0.02rem solid #121e5f;
}
.section3 .left-box .view3 .item:last-child {
  margin-right: 0;
}
.section3 .button_group {
  margin-top: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section3 .button_group .btn {
  width: 1.6rem;
  height: 0.66rem;
  border: 0.02rem solid #d6d6d6;
  background: #ffffff;
  border-radius: 0.5rem;
  font-size: 0.24rem;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section3 .button_group .btn1 {
  border: 0.02rem solid #121e5f;
  background: #121e5f;
  color: #ffffff;
}

.tools-box {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 666;
}
.tools-box .tools {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0.98rem;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  padding: 0 0.3rem;
}
.tools-box .tools .submit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.4rem;
  height: 0.6rem;
  background: #ff6000;
  font-size: 0.28rem;
  color: #ffffff;
  border-radius: 0.5rem;
}
.tools-box .tools .num-box {
  display: flex;
  align-items: center;
}
.tools-box .tools .num-box .item {
  font-size: 0.26rem;
  color: #333333;
  line-height: 0.4rem;
  padding-left: 0.4rem;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 0.24rem 0.24rem;
  background-image: url(../../assets/images/icon92.png);
}

.view3 {
}
.view3 .list {
  padding: 0.2rem 0.4rem;
  height: 6rem;
  overflow-y: scroll;
}
.view3 .list .item {
  width: 0.8rem;
  height: 0.8rem;
  background: #ffffff;
  border: 0.02rem solid #333333;
  font-size: 0.32rem;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: 0.38rem;
  margin-top: 0.3rem;
}
.view3 .list .item:nth-child(1),
.view3 .list .item:nth-child(2),
.view3 .list .item:nth-child(3),
.view3 .list .item:nth-child(4),
.view3 .list .item:nth-child(5),
.view3 .list .item:nth-child(6) {
  margin-top: 0;
}
.view3 .list .item:nth-child(6n) {
  margin-right: 0;
}
.view3 .list .item.green {
  border: 0.02rem solid #1d6bd0;
  background: #c8dcf7;
  color: #1d6bd0;
}
.view3 .list .item.orange {
  border: 0.02rem solid #ff6000;
  background: #ffd5bb;
  color: #ff6000;
}
.view3 .close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0;
  border-top: 0.02rem solid #dddddd;
}
.view3 .close-btn .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.6rem;
  width: 1.5rem;
  background: #121e5f;
  color: #ffffff;
  border-radius: 0.5rem;
  font-size: 0.28rem;
}

.alert-box {
  position: fixed;
  width: 6rem;
  z-index: 999;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  -o-transform: translate(-50%, -50%) scale(0);
  -moz-transform: translate(-50%, -50%) scale(0);
  -webkit-transform: translate(-50%, -50%) scale(0);
  border-radius: 0.14rem;
  padding: 0.4rem 0.6rem;
  transition: all 0.3s;
}
.alert-box.active {
  transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -webkit-transform: translate(-50%, -50%) scale(1);
}
.alert-box .cate-title {
  font-size: 0.32rem;
  color: #333333;
  text-align: center;
  margin-bottom: 0.3rem;
}
.alert-box .desc {
  font-size: 0.26rem;
  color: #000000;
  line-height: 0.42rem;
}
.alert-box .button_group {
  margin-top: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.alert-box .button_group .btn {
  width: 2rem;
  height: 0.7rem;
  border-radius: 0.5rem;
  font-size: 0.28rem;
  color: #ffffff;
  background: #d4d4d4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-box .button_group .btn1 {
  background: #121e5f;
}

.no-list {
  text-align: center;
  padding-top: 1.2rem;
}
.no-list .icon {
  width: 3.5rem;
  height: 2.52rem;
}
.no-list .text {
  font-size: 0.28rem;
  color: #c5c5c5;
  margin-top: 0.7rem;
}
.no-list .btn {
  width: 4rem;
  height: 0.8rem;
  background: #121e5f;
  border-radius: 0.5rem;
  font-size: 0.32rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.8rem auto 0;
}
</style>